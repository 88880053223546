import { postRequest, getRequest } from './index'

// 提货流水分页
export const deliveryInfoPage = (data, successCallback, failureCallback) => {
  postRequest('/deliveryInfo/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货流水详情
export const deliveryInfoDetail = (id, successCallback, failureCallback) => {
  getRequest(`deliveryInfo/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货确认签署
export const deliveryInfoGetEsignUrl = (id, successCallback, failureCallback) => {
  postRequest(`deliveryInfo/getEsignUrl/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货确认签署
export const deliveryInfoConfirmSign = (id, successCallback, failureCallback) => {
  getRequest(`deliveryInfo/confirmSign/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 提货申请用印
export const deliveryInfoSignAudit = (data, successCallback, failureCallback) => {
  postRequest('deliveryInfo/signAudit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 发起提货申请拒绝操作
export const deliveryInfoRefuseDeliveryBill = (data, successCallback, failureCallback) => {
  postRequest('deliveryInfo/refuseDeliveryBill', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
